import { StrategyAPISwagger } from '~/configs/apirc/StrategyAPI.swagger.d'
import { Urlu } from '~/modules/SDK/app/urlu/Urlu'

export type StrategiesSCResourceData = {
  strategyId: number
  strategyName: string
  symbol: string
  price: number
  datetime: string
  qty: number
  winRate1M: number
  profit1M: number
  drawdown1M: number
  winRate3M: number
  profit3M: number
  drawdown3M: number
  winRate6M: number
  profit6M: number
  drawdown6M: number
  winRate1Y: number
  profit1Y: number
  drawdown1Y: number
  winRateYtd: number
  profitYtd: number
  drawdownYtd: number
  winRateAll: number
  profitAll: number
  drawdownAll: number
}

/** By https://strategy-center.futures-op.com/swagger/index.html */
export class StrategyAPI2 extends Urlu<StrategyAPISwagger> {
  /** 這邊專用的 jwt */
  get jwt() {
    return 'futuresai'
  }

  //
  // admin
  getAdminSignalConnections = this.createQuery('get', '/api/admin/signalr/connections')
  getAdminTaskMatchUp = this.createQuery('get', '/api/admin/task/matchUp')
  getAdminTaskClearOrders = this.createQuery('get', '/api/admin/task/clearOrders')
  getAdminTaskSettleContract = this.createQuery('get', '/api/admin/task/settleContract')
  getAdminTaskCalculateDailyProfit = this.createQuery('get', '/api/admin/task/calculateDailyProfit')
  getAdminTaskCalculatePerformanceOverview = this.createQuery(
    'get',
    '/api/admin/task/calculatePerformanceOverview',
  )
  searchAdminStrategies = this.createQuery('get', '/api/admin/strategies/search')
  getAdminStrategyTag = this.createQuery('get', '/api/admin/strategies/tag')
  newAdminStrategyTag = this.createQuery('post', '/api/admin/strategies/tag')
  deleteAdminStrategyTag = this.createQuery('delete', '/api/admin/strategies/tag')

  //
  // ContractMapping
  getContract = this.createQuery('get', '/api/contract')
  newContract = this.createQuery('post', '/api/contract')

  //
  // Order
  getOrders = this.createQuery('get', '/api/strategies/{strategyName}/orders')
  newOrder = this.createQuery('post', '/api/strategies/{strategyName}/orders')
  getOrdersPending = this.createQuery('get', '/api/strategies/{strategyName}/orders/pending')
  newOrdersSimulated = this.createQuery('post', '/api/strategies/{strategyName}/orders/simulated')
  newOrdersSimulatedList = this.createQuery(
    'post',
    '/api/strategies/{strategyName}/orders/simulatedList',
  )
  updateOrders = this.createQuery('put', '/api/strategies/{strategyName}/orders/{id}')
  deleteOrders = this.createQuery('delete', '/api/strategies/{strategyName}/orders/{id}')

  //
  // Strategy
  getStrategyByName = this.createQuery('get', '/api/strategies/{name}')
  updateStrategyByName = this.createQuery('put', '/api/strategies/{name}')
  deleteStrategyByName = this.createQuery('delete', '/api/strategies/{name}')
  newStrategy = this.createQuery('post', '/api/strategies')
  getStrategyPositions = this.createQuery('get', '/api/strategies/{name}/positions')
  getStrategyRecords = this.createQuery('delete', '/api/strategies/{name}/records')
  getStrategyDailyProfit = this.createQuery('get', '/api/strategies/{name}/dailyProfit')

  //
  // PerformanceSummary
  getOverviewByStrategy = this.createQuery('get', '/api/overviews/{strategyName}')
  getOverviewsByAll = this.createQuery('get', '/api/overviews')

  //
  // Transaction
  getStrategyTransactions = this.createQuery('get', '/api/strategies/{strategyName}/transactions')
  getStrategyTransactionsStatements = this.createQuery(
    'get',
    '/api/strategies/{strategyName}/transactions/statements',
  )

  //
  // User
  signIn = this.createQuery('post', '/api/user/signIn')
  getUser = this.createQuery('get', '/api/user')
  deleteUser = this.createQuery('delete', '/api/user')
  getUserStrategies = this.createQuery('get', '/api/user/strategies')
  updatePassword = this.createQuery('put', '/api/user/password')
  updateName = this.createQuery('put', '/api/user/name')

  /**
   * @example
   *   //
   *   // 以前寫法
   *   export const useStrategiesResource = () => {
   *     const url = urlcat(strategyCenterURL, 'api/overviews', { simulated: true })
   *     const res = useSWR<StrategiesSCResourceData[]>([url], fetcherWithToken, {
   *       revalidateOnFocus: false,
   *     })
   *     return res.data ?? []
   *   }
   */
  // getOverviewsByAll = new Urlu.Query(() => {
  //   return this.request.axios
  //     .get<StrategiesSCResourceData[]>(`/overviews`, {
  //       params: {
  //         simulated: true,
  //       },
  //     })
  //     .then(res => {
  //       return res.data
  //     })
  // })

  /**
   * @example
   *   //
   *   // 以前寫法
   *   export const useSpecStrategyOverview = (strategyName: string) => {
   *     const url = urlcat(strategyCenterURL, 'api/overviews/:name', {
   *       name: strategyName,
   *       simulated: true,
   *     })
   *     const res = useSWR<StrategiesSCResourceData>([url], fetcherWithToken, {
   *       revalidateOnFocus: false,
   *     })
   *     return res.data
   *   }
   */
  // getOverview = new Urlu.Query((strategyName: string) => {
  //   if (!strategyName) {
  //     return undefined
  //   }

  //   return this.request.axios
  //     .get<StrategiesSCResourceData>(`/overviews/:name`, {
  //       params: {
  //         name: strategyName,
  //         simulated: true,
  //       },
  //     })
  //     .then(res => {
  //       return res.data
  //     })
  // })

  /**
   * @example
   *   //
   *   // 以前寫法
   *   export const useSpecStrategyInfo = (strategyName: string) => {
   *     const url = urlcat(strategyCenterURL, 'api/strategies/:name', {
   *       name: strategyName,
   *       simulated: true,
   *     })
   *     const res = useSWR<Trade.Strategy>([url], fetcherWithToken, {
   *       revalidateOnFocus: false,
   *     })
   *     return res.data
   *   }
   */
  // getStrategy = new Urlu.Query((strategyName: string) => {
  //   if (!strategyName) {
  //     return undefined
  //   }

  //   return this.request.axios
  //     .get<Trade.Strategy>(`/strategies/:name`, {
  //       params: {
  //         name: strategyName,
  //         simulated: true,
  //       },
  //     })
  //     .then(res => {
  //       return res.data
  //     })
  // })

  /**
   * @example
   *   //
   *   // 以前寫法
   *   export const useSpecStrategyStatement = (
   *     strategyName: string,
   *     from?: string,
   *     to?: string,
   *   ) => {
   *     const url = urlcat(strategyCenterURL, 'api/strategies/:name/transactions/statements', {
   *       name: strategyName,
   *       beginDatetime: from,
   *       endDatetime: to,
   *       simulated: true,
   *     })
   *     const res = useSWR<Trade.Statement[]>([url], fetcherWithToken, {
   *       revalidateOnFocus: false,
   *     })
   *     return res.data ?? []
   *   }
   */
  // getStrategyTransactionStatement = new Urlu.Query(
  //   (params: { strategyName: string; from?: string; to?: string }) => {
  //     return this.request.axios
  //       .get<Trade.Statement[]>(`/strategies/:name/transactions/statements`, {
  //         params: {
  //           name: params.strategyName,
  //           beginDatetime: params.from,
  //           endDatetime: params.to,
  //           simulated: true,
  //         },
  //       })
  //       .then(res => {
  //         return res.data
  //       })
  //   },
  // )

  /**
   * @example
   *   //
   *   // 以前寫法
   *   export const useSpecStrategyDailyProfit = (
   *     strategyName: string,
   *     from?: string,
   *     to?: string,
   *   ) => {
   *     const url = urlcat(strategyCenterURL, 'api/strategies/:name/dailyProfit', {
   *       name: strategyName,
   *       beginDatetime: from,
   *       endDatetime: to,
   *       simulated: true,
   *     })
   *     const res = useSWR<Trade.DailyProfit[]>([url], fetcherWithToken, {
   *       revalidateOnFocus: false,
   *     })
   *     return res.data ?? []
   *   }
   */
  // getStrategyDailyProfit = new Urlu.Query(
  //   (params: { strategyName: string; from?: string; to?: string }) => {
  //     return this.request.axios
  //       .get<Trade.DailyProfit[]>(`/strategies/:name/dailyProfit`, {
  //         params,
  //       })
  //       .then(res => {
  //         return res.data
  //       })
  //   },
  // )
}
