import { entries, isNull } from 'lodash'
import mixpanel from 'mixpanel-browser'
import Router from 'next/router'
import { ENV } from '~/configs/ENV'
import { GA_TRACKING_ID } from '~/configs/GA_TRACKING_ID'
import { useMeStore } from '~/modules/SDK/me/useMeStore'
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents'
import { fr_me } from '~/pages/heineken_template/_fr/fr_me'
import { __DEV__ } from '~/utils/__DEV__'
import { __TYPING__ } from '~/utils/__TYPING__'

mixpanel.init('dc0220b0e950d9853c5c043f397590b5', {
  debug: __DEV__ ? true : false,
  track_pageview: true,
  persistence: 'localStorage',
  persistence_name: 'futuresai.mixpanel_analytics',
})

/**
 * `key` 與 `value` 皆會連動到 mixpanel 的數據，
 *
 * ## 一旦變更，
 *
 * 可能會影響過往資料不容易撈取，或是失真；變動之前，需留意多加思考
 */
type UserEvent = {
  股期篩選切換: {
    before: boolean
    now: boolean
  }
}

export const gaAPI = {
  initPageViewOnRouteChange() {
    Router.events.on('routeChangeComplete', url => gaAPI.pageview(url))
  },
  pageview(url: string) {
    gtag('config', GA_TRACKING_ID, {
      page_path: url,
    })
  },
  event(events: Partial<UserEvent>) {
    const uid = fr_me._ourMe?.uid || useMeStore.getState().meUserState?.uid || __UNSET__

    const name =
      fr_me.me.displayName ||
      useMeStore.getState().meInfoState?.name ||
      useMeStore.getState().meUserState?.name ||
      __UNSET__

    const email = fr_me._firebaseMe?.email || useMeStore.getState().meUserState?.email || __UNSET__

    const defaultsEventData = {
      uid: uid,
      userName: name,
      userEmail: email,
      commitBranch: ENV.BRANCH,
      commitHash: ENV.COMMITHASH,
      commitDate: ENV.VERSION,
      agentName: fr_agents.agent,
      agentProduct: fr_agents.agentProduct,
      agentProductName: fr_agents.product,
    }

    for (const [eventName, eventData] of entries(events)) {
      mixpanel.track(eventName, {
        ...defaultsEventData,
        ...(isNull(eventData) ? undefined : eventData),
      })
    }
  },
}

const __UNSET__ = '__UNSET__'
